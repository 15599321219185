/* eslint-disable curly */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-console */
import * as Domain from '../models/Domain';
import Services from '@/services/Services';
import Vue from 'vue';

let debug = process.env.NODE_ENV !== 'production';
let debugDeep = process.env.NODE_ENV !== 'production';
let debugPrefix = ' PersistanceHelper ';

export async function initialize(): Promise<void> {
  debug = await Services.IsDebugOverride('PersistanceHelper');
  debugDeep = await Services.IsDebugOverride('PersistanceHelperDeep');
  debugPrefix = Services.GetGenericChalkCategory(debugPrefix.trim());
}

function update(original: any, updated: any): any {
  let propertyIndex;
  let descriptor;
  let keys;
  let current;
  let nextSource;
  let proto;
  let target = original === null ? Object.create(Domain.DomainTypes.get(updated.type).prototype) : original;
  const copies = [{
    source: updated,
    target: target,
  }];
  const final = copies[0].target;

  // First in, first out
  while ((current = copies.shift())) {

    // add properties on object that prototype didn't include (maps)
    if (current.target instanceof Domain.User) {
    } else if (current.target instanceof Domain.Studio) {
      if (current.target.teammates === undefined) Vue.set(current.target, 'teammates', new Map<string, Domain.Teammate>());
      if (current.target.actions === undefined) Vue.set(current.target, 'actions', new Map<string, Domain.Action>());
      if (current.target.userStates === undefined) Vue.set(current.target, 'userStates', new Map<string, Domain.StudioUserState>());
      if (current.target.featureFlags === undefined) Vue.set(current.target, 'featureFlags', new Map<string, string>());
      if (current.target.toDoTemplates === undefined) Vue.set(current.target, 'toDoTemplates', new Map<string, Domain.ToDoTemplate>());
      if (current.target.tags === undefined) Vue.set(current.target, 'tags', new Map<string, Domain.Tag>());
    } else if (current.target instanceof Domain.ToDo) {
      if (current.target.actions === undefined) Vue.set(current.target, 'actions', new Map<string, Domain.Action>());
      if (current.target.userStates === undefined) Vue.set(current.target, 'userStates', new Map<string, Domain.ToDoUserState>());
      if (current.target.tags === undefined) Vue.set(current.target, 'tags', new Map<string, Domain.Tag>());
      if (current.target.steps === undefined) Vue.set(current.target, 'steps', new Map<string, Domain.Step>());
    } else if (current.target instanceof Domain.User) {
      if (current.target.tags === undefined) Vue.set(current.target, 'tags', new Map<string, Domain.Tag>());
      if (current.target.featureFlags === undefined) Vue.set(current.target, 'featureFlags', new Map<string, string>());
    } else if (current.target instanceof Domain.PublicUser) {
      if (current.target.tags === undefined) Vue.set(current.target, 'tags', new Map<string, Domain.Tag>());
    } else if (current.target instanceof Domain.Teammate) {
      if (current.target.tags === undefined) Vue.set(current.target, 'tags', new Map<string, Domain.Tag>());
    }else if (current.target instanceof Domain.Organization) {
      if (current.target.featureFlags === undefined) Vue.set(current.target, 'featureFlags', new Map<string, string>());
    }
    if (debugDeep) console.debug(debugPrefix, 'object', current);

    keys = Object.getOwnPropertyNames(current.source);
    for (propertyIndex = 0; propertyIndex < keys.length; propertyIndex++) {
      if (debugDeep) console.debug(debugPrefix, 'property', keys[propertyIndex]);

      // Save the source's descriptor
      descriptor = Object.getOwnPropertyDescriptor(current.source, keys[propertyIndex]);

      if (!descriptor.value || typeof descriptor.value !== 'object') {
        Vue.set(current.target, keys[propertyIndex], descriptor.value);
        continue;
      }

      nextSource = descriptor.value;
      proto = Object.getPrototypeOf(descriptor.value);

      // fixup source maps that were empty... (they don't deserialize to a map prototype)
      if (current.target instanceof Domain.User) {
      } else if (current.target instanceof Domain.Studio) {
        if (keys[propertyIndex] === 'teammates' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Teammate>();
        if (keys[propertyIndex] === 'actions' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Action>();
        if (keys[propertyIndex] === 'userStates' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.StudioUserState>();
        if (keys[propertyIndex] === 'featureFlags' && proto !== Map.prototype) descriptor.value = new Map<string, string>();
        if (keys[propertyIndex] === 'toDoTemplates' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.ToDoTemplate>();
        if (keys[propertyIndex] === 'tags' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Tag>();
      } else if (current.target instanceof Domain.ToDo) {
        if (keys[propertyIndex] === 'actions' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Action>();
        if (keys[propertyIndex] === 'userStates' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.ToDoUserState>();
        if (keys[propertyIndex] === 'tags' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Tag>();
        if (keys[propertyIndex] === 'steps' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Step>();
      } else if (current.target instanceof Domain.User) {
        if (keys[propertyIndex] === 'tags' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Tag>();
        if (keys[propertyIndex] === 'featureFlags' && proto !== Map.prototype) descriptor.value = new Map<string, string>();
      } else if (current.target instanceof Domain.PublicUser) {
        if (keys[propertyIndex] === 'tags' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Tag>();
      } else if (current.target instanceof Domain.Teammate) {
        if (keys[propertyIndex] === 'tags' && proto !== Map.prototype) descriptor.value = new Map<string, Domain.Tag>();
      } else if (current.target instanceof Domain.Organization) {
        if (keys[propertyIndex] === 'featureFlags' && proto !== Map.prototype) descriptor.value = new Map<string, string>();
      }
      proto = Object.getPrototypeOf(descriptor.value); // refresh value

      // Opaque objects, like Date, not recursivity for them
      if (update.opaque.has(proto)) {
        descriptor.value = clone.opaque.get(proto)(descriptor.value);
        Vue.set(current.target, keys[propertyIndex], descriptor.value);
        continue;
      }
      if (proto !== Map.prototype && descriptor.value.type === undefined) { // no type, copy as is with no recusivity
        Vue.set(current.target, keys[propertyIndex], descriptor.value);
        continue;
      }

      // maps 
      if (proto === Map.prototype) {
        const descriptorTarget = Object.getOwnPropertyDescriptor(current.target, keys[propertyIndex]);
        if (descriptorTarget === undefined) throw new Error('cannot be undefined');
        const currentItemsInTarget = new Map<string, string>();
        let m = descriptorTarget.value;
        if (m === undefined && descriptorTarget.get !== undefined) m = descriptorTarget.get();
        for (const key of m.keys()) {
          currentItemsInTarget.set(key, key);
        }

        // upserts
        for (const [key, value] of descriptor?.value) { // upserts
          if (m.has(key)) {
            if (debugDeep) console.debug(debugPrefix, 'map update target', m.get(key));
            if (typeof value === "string") m.set(key, value);
            else {
              update(m.get(key), value);
            }
            currentItemsInTarget.delete(key);
          } else {
            if (debugDeep) console.debug(debugPrefix, 'map add to target', key);
            if (typeof value === "string") m.set(key, value);
            else {
              const created = update(null, value);
              m.set(key, created);
            }
          }
        }
        // removals
        for (const key of currentItemsInTarget.keys()) { // removes
          if (debugDeep) console.debug(debugPrefix, 'map remove from target', m.get(key));
          m.delete(key); // remove items not referenced in source
        }
        continue;
      }

      if (Array.isArray(nextSource)) {
        descriptor.value = [];
      } else if (descriptor.value.type !== undefined) {
        descriptor.value = Object.create(Domain.DomainTypes.get(descriptor.value.type).prototype);
      } else {
        throw new Error('unknown case');
      }

      Vue.set(current.target, keys[propertyIndex], descriptor.value);
      copies.push({ source: nextSource, target: descriptor.value });
    }
  }

  return final;
}

update.opaque = new Map();
update.opaque.set(Date.prototype, src => new Date(src));
update.opaque.set(String.prototype, src => new String(src));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function clone(originalObject: any, circular: boolean = false): any {
  // First create an empty object with
  // same prototype of our original source
  const originalProto = Object.getPrototypeOf(originalObject);

  // Opaque objects, like Date
  if (clone.opaque.has(originalProto)) { return clone.opaque.get(originalProto)(originalObject); }

  let propertyIndex;
  let descriptor;
  let keys;
  let current;
  let nextSource;
  let proto;
  const copies = [{
    source: originalObject,
    target: Array.isArray(originalObject) ? [] : Object.create(originalProto),
  }];
  const cloneObject = copies[0].target;
  const refMap = new Map();
  refMap.set(originalObject, cloneObject);

  // First in, first out
  while ((current = copies.shift())) {
    keys = Object.getOwnPropertyNames(current.source);

    for (propertyIndex = 0; propertyIndex < keys.length; propertyIndex++) {

      if (keys[propertyIndex] === '__ob__') continue;

      // Save the source's descriptor
      descriptor = Object.getOwnPropertyDescriptor(current.source, keys[propertyIndex]);

      if (!descriptor.value || typeof descriptor.value !== 'object') {
        Object.defineProperty(current.target, keys[propertyIndex], descriptor);
        continue;
      }

      nextSource = descriptor.value;

      if (circular) {
        if (refMap.has(nextSource)) {
          // The source is already referenced, just assign reference
          descriptor.value = refMap.get(nextSource);
          Object.defineProperty(current.target, keys[propertyIndex], descriptor);
          continue;
        }
      }

      proto = Object.getPrototypeOf(descriptor.value);

      // Opaque objects, like Date, not recursivity for them
      if (clone.opaque.has(proto)) {
        descriptor.value = clone.opaque.get(proto)(descriptor.value);
        Object.defineProperty(current.target, keys[propertyIndex], descriptor);
        continue;
      }

      descriptor.value = Array.isArray(nextSource) ? [] : Object.create(proto);

      if (circular) { refMap.set(nextSource, descriptor.value); }
      Object.defineProperty(current.target, keys[propertyIndex], descriptor);
      copies.push({ source: nextSource, target: descriptor.value });
    }
  }
  return cloneObject;
}

clone.opaque = new Map();
clone.opaque.set(Date.prototype, src => new Date(src));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CloneForPersistance(originalObject: any, name: string): any {
  if (debugDeep) console.groupCollapsed(debugPrefix, `Clone ${name}`, '\nOriginal', originalObject);
  try {
    const cloned = clone(originalObject);
    if (debugDeep) console.debug(debugPrefix, 'Cloned', cloned);
    if (debug && debugDeep === false) console.debug(debugPrefix, `Clone ${name}`, '\nOriginal', originalObject, '\nCloned', cloned);
    return cloned;
  } finally {
    if (debugDeep) console.groupEnd();
  }
}

export function Update(original: any | null, updated: any, name: string): any {

  // check etag...
  if (original !== null) {
    if (original.etag === updated.etag) {
      if (debug && debugDeep === false) console.debug(debugPrefix, `Update ETAG MATCH ${name}`, '\nOriginal', original, '\nUpdated', updated);
      return original;
    }
  }

  if (debugDeep) console.groupCollapsed(debugPrefix, `Update ${name}`, '\nOriginal', original, '\nUpdated', updated);
  try {
    const final = update(original, updated);
    if (debugDeep) console.debug(debugPrefix, 'Final', final);
    if (debug && debugDeep === false) console.debug(debugPrefix, `Update ${name}`, '\nOriginal', original, '\nUpdated', updated);
    return final;
  } finally {
    if (debugDeep) console.groupEnd();
  }
}
