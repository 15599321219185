// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
export function capitalize(s: string): string {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function truncate(title: string, length: number): string {
  if (title.length > length) {
    return title.substring(0, length) + ' ...';
  } else {
    return title;
  }
}

export function truncateStart(title: string, length: number): string {
  if (title.length > length) {
    return '... ' + title.substring((title.length - length), title.length);
  } else {
    return title;
  }
}

export function toPastelColor(str: string): string {
  let hash: number = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    // hash = str.charCodeAt(i) + hash;
  }

  const hue = hash % 360;
  const saturation = hash % 10 + 85;
  const lightness = hash % 4 + 88;

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

export function toDarkColor(str: string): string {
  let hash: number = 0;
  for (const char of str) {
    // tslint:disable-next-line:no-bitwise
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return 'hsl(' + hue.toString(10) + ', 100%, 50%, 50%)';
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
};
