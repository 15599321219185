/* eslint-disable curly */
// tslint:disable:max-classes-per-file
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { Route } from 'vue-router';
import store from '../plugins/vuex';
import Services from '../services/Services';
import * as AppModuleDomain from '../models/AppModuleDomain';
import * as Domain from '@/models/Domain';
import * as DateHelper from '@/helpers/DateHelper';
import i18n from '@/plugins/i18n';
import * as PersistanceHelper from '@/helpers/PersistanceHelper';
import * as I18NHelper from '@/helpers/I18NHelper';


@Module({ dynamic: true, store, namespaced: true, name: 'app' })
export default class AppModule extends VuexModule {
  public currentRoute: Route | null = null;
  public previousRoute: Route | null = null;

  public lastStudioId: string = '00000000-0000-0000-0000-000000000000';

  public isSynching: boolean = false;
  public isFirstSyncDone: boolean = false;
  public isForceSynching: boolean = false;
  public isBrowserOnline: boolean = navigator.onLine;
  public isBrowserVisible: boolean = document.hidden === false;
  public isLive: boolean = false;
  public isSyncError: boolean = false;
  public isForceOffline: boolean = false;
  public pendingMutationsToSyncCount: number = 0;

  public isAppWaiting: boolean = false;
  public serviceWorkerReady: boolean = false;

  public user: Domain.User | null = null;

  public currentPeriod: Domain.EnumPeriod = Domain.EnumPeriod.none;
  public currentDate: string = '';

  public get hasPendingMutationsToSync(): boolean {
    return this.pendingMutationsToSyncCount !== 0;
  }

  public get isOnline(): boolean {
    if (!this.context.rootGetters['config/isServerConfigured']) {
      return false;
    }
    if (this.isForceOffline) {
      return false;
    }
    return this.isBrowserOnline;
  }

  public get copilotToDoTipCount(): number {
    return store.state.tip.tip.kind === 'fix' ? store.state.tip.tip.toDoTips.length : 0;
  }

  public get platform(): Domain.EnumPlatform {
    const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
    const windowsPlatforms = /(win32|win64|windows|wince)/i;
    const userAgent = navigator.userAgent.toLowerCase();

    if (macosPlatforms.test(userAgent)) { return Domain.EnumPlatform.mac; }
    if (windowsPlatforms.test(userAgent)) { return Domain.EnumPlatform.windows; }
    if (/iphone/.test(userAgent)) { return Domain.EnumPlatform.iphone; }
    if (/ipad/.test(userAgent)) { return Domain.EnumPlatform.ipad; }
    if (/android/.test(userAgent)) { return Domain.EnumPlatform.android; }
    if ((/linux/.test(userAgent)) && (/cros/.test(userAgent))) { return Domain.EnumPlatform.chromebook; }

    return Domain.EnumPlatform.other;
  }

  public get browser(): Domain.EnumBrowser {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('edg')) return Domain.EnumBrowser.edge;
    if (userAgent.includes('chrome')) return Domain.EnumBrowser.chrome;
    if (userAgent.includes('safari')) return Domain.EnumBrowser.safari;

    return Domain.EnumBrowser.other;
  }

  public get browserContainer(): Domain.EnumBrowserContainer {
    if (this.browser === Domain.EnumBrowser.edge) {
      const brands = (navigator as any).userAgentData.brands;
      const sidebarBrandInfo = brands.find((brand) => brand.brand === 'Edge Side Panel');
      if (sidebarBrandInfo) return Domain.EnumBrowserContainer.sidePanel;
    }

    if (["fullscreen", "standalone", "minimal-ui"].some(
      (displayMode) => window.matchMedia('(display-mode: ' + displayMode + ')').matches) === true) return Domain.EnumBrowserContainer.app;

    return Domain.EnumBrowserContainer.browser;
  }

  public get isRouteSafeToShow(): boolean {
    if (this.user && this.currentRoute && this.user.status === Domain.EnumUserStatus.awaitingApprouval && this.currentRoute?.name === 'invite') return true;
    if (this.user && this.user.status !== Domain.EnumUserStatus.active) return false;

    if (this.currentRoute === null) return true; // if router hasn't initalized yet, assume it's safe
    if (this.currentRoute.meta && this.currentRoute.meta.noAuthRequired) return true;

    if (this.context.rootGetters['config/isServerConfigured'] === true && this.isFirstSyncDone === false) return false;

    if (this.user === null) return false;

    return true;
  }

  @Mutation
  public provideFeedback(payload: AppModuleDomain.ProvideFeedbackMutation): void {
    // do nothing - work is done only on the server
  }

  @Mutation
  public setLoggedInUser(payload: AppModuleDomain.SetLoggedInUserMutation): void {
    if (payload.persistedUser !== null) PersistanceHelper.Update(payload.user, payload.persistedUser, 'meUser auth');
    this.user = payload.user;

    I18NHelper.setLocale(i18n, payload.user?.locale ?? I18NHelper.getClosestLocale(navigator.language));
  }

  @Mutation
  public restoreMeUser(payload: AppModuleDomain.RestoreMeUserMutation): void {
    PersistanceHelper.Update(this.user, payload.user, 'meUser');

    if (payload.user.isProfileConfirmed) I18NHelper.setLocale(i18n, payload.user.locale);
  }

  @Mutation
  public confirmProfileInitial(payload: AppModuleDomain.ConfirmProfileInitialMutation): void {
    if (this.user !== null) {
      this.user.color = payload.color;
      this.user.firstName = payload.firstName;
      this.user.lastName = payload.lastName;
      this.user.nickname = payload.nickname;
      this.user.locale = payload.locale;
      this.user.pictureUrl = payload.pictureUrl;
      this.user.isProfileConfirmed = true;
    }
  }

  @Mutation
  public changeProfileNickname(payload: AppModuleDomain.ChangeProfileNicknameMutation): void {
    if (this.user !== null) {
      this.user.nickname = payload.nickname;
    }
  }

  @Mutation
  public changeProfileFirstName(payload: AppModuleDomain.ChangeProfileFirstNameMutation): void {
    if (this.user !== null) {
      this.user.firstName = payload.firstName;
      this.user.color = payload.color;
    }
  }

  @Mutation
  public changeProfileLastName(payload: AppModuleDomain.ChangeProfileLastNameMutation): void {
    if (this.user !== null) {
      this.user.lastName = payload.lastName;
      this.user.color = payload.color;
    }
  }

  @Mutation
  public changeProfileLocale(payload: AppModuleDomain.ChangeProfileLocaleMutation): void {
    if (this.user !== null) {
      this.user.locale = payload.locale;
      I18NHelper.setLocale(i18n, payload.locale);
    }
  }

  @Mutation
  public changeProfilePictureUrl(payload: AppModuleDomain.ChangeProfilePictureUrlMutation): void {
    if (this.user !== null) {
      this.user.pictureUrl = payload.pictureUrl;
    }
  }

  @Mutation
  public setServiceWorkerReady(payload: AppModuleDomain.SetServiceWorkerReadyMutation): void {
    this.serviceWorkerReady = payload.isServiceWorkerReady;
  }

  @Mutation
  public setSynchingStatus(payload: AppModuleDomain.SetSynchingStatusMutation): void {
    this.isSynching = payload.isSynching;

    if (payload.isSynching === false && this.isFirstSyncDone === false) {
      this.isFirstSyncDone = true;
    }

    if (payload.isSynching === false && this.isForceSynching === true) {
      this.isForceSynching = false;
    }

    this.isSyncError = payload.isSyncError;
  }

  @Mutation
  public setUnsentMutationsCount(payload: AppModuleDomain.SetUnsentMutationsCountMutation): void {
    if (!store.state.config.isServerConfigured) {
      this.pendingMutationsToSyncCount = 0;
      return;
    }
    this.pendingMutationsToSyncCount = payload.pendingMutationsToSyncCount;
  }

  @Mutation
  public setBrowserOnline(payload: AppModuleDomain.SetBrowserOnlineMutation): void {
    this.isBrowserOnline = payload.isOnline;
    this.isBrowserVisible = payload.isVisible;
  }

  @Mutation
  public setForceOffline(payload: AppModuleDomain.SetForceOfflineMutation): void {
    this.isForceOffline = payload.force;
  }

  @Mutation
  public setIsAppWaiting(payload: AppModuleDomain.SetIsAppWaiting): void {
    this.isAppWaiting = payload.isAppWaiting;
  }

  // eslint-disable-next-line no-empty-function
  @Mutation
  public forceSyncWithServer(): void { // signature payload: AppModuleDomain.ForceSyncWithServerMutation
    this.isForceSynching = true;
  }

  @Mutation
  public changedRoute(payload: AppModuleDomain.ChangedRouteMutation): void {
    this.currentRoute = payload.currentRoute;
    this.previousRoute = payload.previousRoute;
  }

  @Mutation
  public restore(payload: AppModuleDomain.RestoreMutation): void {
    this.lastStudioId = payload.lastStudioId;
  }

  @Mutation
  public setLastStudioRouteStudioId(payload: AppModuleDomain.SetLastStudioRouteStudioIdMutation): void {
    if (payload.studioId === undefined) {
      throw new Error('payload studioId cannot be undefined');
    }
    this.lastStudioId = payload.studioId;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  @Action
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public async initialize(payload: AppModuleDomain.InitializeAction): Promise<void> {
    const meUser = await Services.AppStorage.getItem('me') as Domain.User | null;

    this.context.commit(AppModuleDomain.InternalInitializeMutation.MutationName, new AppModuleDomain.InternalInitializeMutation(meUser));
  }

  @Mutation
  public internalInitialize(payload: AppModuleDomain.InternalInitializeMutation): void { // eslint-disable-line @typescript-eslint/no-unused-vars

    this.currentPeriod = DateHelper.getPeriodFromNow();
    this.currentDate = DateHelper.getDateFromNow();

    if (payload.meUser) I18NHelper.setLocale(i18n, payload.meUser?.locale);
    else I18NHelper.setLocale(i18n, I18NHelper.getClosestLocale(navigator.language));

    this.isFirstSyncDone = payload.meUser !== null;
  }

  @Mutation
  public periodChanged(payload: AppModuleDomain.PeriodChangedMutation): void { // eslint-disable-line @typescript-eslint/no-unused-vars
    this.currentPeriod = DateHelper.getPeriodFromNow();
    this.currentDate = DateHelper.getDateFromNow();
  }

  @Mutation
  public vote(payload: AppModuleDomain.VoteMutation): void {
    Services.AppInsights.trackEvent(
      { name: 'vote' },
      { userId: payload.userId, studioId: payload.studioId, toDoId: payload.toDoId, feature: payload.feature, choice: payload.choice, comment: payload.comment },
    );
  }
}
