// tslint:disable:max-classes-per-file
// tslint:disable:max-line-length
import * as Domain from '../models/Domain';

//
// timestamp
//
export function getTimestampByAddingDaysAfterNow(days: number): number {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  const now = new Date();
  const nowInMs = now.getTime();
  return (nowInMs + (oneDayInMs * days));
}

export function getTimestampByAddingHoursAfterNow(hours: number): number {
  const oneHourInMs: number = 60 * 60 * 1000;
  const now = new Date();
  const nowInMs = now.getTime();
  return (nowInMs + (oneHourInMs * hours));
}

export function getTimestampByAddingDaysAfterTimestamp(days: number, timestamp: number): number {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  return (timestamp + (oneDayInMs * days));
}

export function getTimestampByAddingDaysAfterDate(days: number, isoDate: string): number {
  if (isoDate === '') { 
    const now = new Date();  
    return now.getTime();
  } // Defensive code

  const year: number = parseInt(isoDate.substring(0, 4));
  const month: number = parseInt(isoDate.substring(5, 7)) - 1;
  const day: number = parseInt(isoDate.substring(8, 10));
  const date = new Date(year, month, day, 0, 0, 0, 0);
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  return (date.getTime() + (oneDayInMs * days));
}

export function getTimestampByAddingMsAfterDate(ms: number, isoDate: string): number {
  if (isoDate === '') { 
    const now = new Date();  
    return now.getTime();
  } // Defensive code

  const year: number = parseInt(isoDate.substring(0, 4));
  const month: number = parseInt(isoDate.substring(5, 7)) - 1;
  const day: number = parseInt(isoDate.substring(8, 10));
  const date = new Date(year, month, day, 0, 0, 0, 0);
  return (date.getTime() + ms);
}

//
// Date ISO
//
export function getDateFromTimestamp(timestamp: number): string {
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  return year.toString() + '-' + (month + 1).toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0'); // month is 0-based
}

export function getDateFromNow(): string {
  const now = new Date();  
  return getDateFromTimestamp(now.getTime());
}

export function getDateByAddingDaysAfterToday(days: number): string {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  const now = new Date();
  const nowInMs = now.getTime();
  return getDateFromTimestamp(nowInMs + (oneDayInMs * days));
}

export function getDateByAddingDaysAfterTimestamp(days: number, timestamp: number): string {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  return getDateFromTimestamp(timestamp + (oneDayInMs * days));
}

export function getTimeFromTimestamp(timestamp: number): string {
  const date = new Date(timestamp);
  const hour = date.getHours();
  const minutes = date.getMinutes();
  return hour.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
}

//
// Date constructor
//
export function getYearFromDate(date: string): number {
  return parseInt(date.slice(0, 4));
}
export function getMonthIndexFromDate(date: string): number {
  return parseInt(date.slice(5, 7)) - 1;
}
export function getDayFromDate(date: string): number {
  return parseInt(date.slice(8, 10));
}

export function getHourFromPeriod(period: Domain.EnumPeriod): number {
  return (period === Domain.EnumPeriod.overnight) ? 0 : (period === Domain.EnumPeriod.morning) ? 6 : (period === Domain.EnumPeriod.afternoon) ? 12 : 18;
}
export function getHourFromTimestamp(timestamp: number): number {
  const date = new Date(timestamp);
  return date.getHours();
}

export function getHourFromTime(time: string): number {
  return parseInt(time.slice(0, 2));
}
export function getMinuteFromTime(time: string): number {
  return parseInt(time.slice(3, 5));
}

//
// Date Day
//
export function isNowDuringDateDay(date: string): boolean {
  const now = new Date();
  return ((now.getFullYear() === getYearFromDate(date)) && (now.getMonth() === getMonthIndexFromDate(date)) && (now.getDate() === getDayFromDate(date)));
}

export function isNowAfterDateDay(date: string): boolean {
  const dateYear = getYearFromDate(date);
  const dateMonth = getMonthIndexFromDate(date);
  const dateDay = getDayFromDate(date);

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();

  if (nowYear > dateYear) { return true; }
  if (nowYear < dateYear) { return false; }
  if (nowMonth > dateMonth) { return true; }
  if (nowMonth < dateMonth) { return false; }
  if (nowDay > dateDay) { return true; }
  return false;
}

export function isNowDuringOrAfterDateDay(date: string): boolean {
  return isNowDuringDateDay(date) || isNowAfterDateDay(date);
}

export function isNowBeforeDateDay(date: string): boolean {
  const dateYear = getYearFromDate(date);
  const dateMonth = getMonthIndexFromDate(date);
  const dateDay = getDayFromDate(date);

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();

  if (nowYear > dateYear) { return false; }
  if (nowYear < dateYear) { return true; }
  if (nowMonth > dateMonth) { return false; }
  if (nowMonth < dateMonth) { return true; }
  if (nowDay < dateDay) { return true; }
  return false;
}

export function isNowAfterEscalationDay(date: string): boolean {
  const dateTimestamp = new Date(getYearFromDate(date), getMonthIndexFromDate(date), getDayFromDate(date), 0, 0, 0, 0).getTime();
  const escalationDelayInMs: number = 60 * 60 * 24 * 1000 * 2;  // 2 days 
  const escalationTimestamp = dateTimestamp + escalationDelayInMs;
  const escalationInstant = new Date(escalationTimestamp);
  const escalationInstantYear = escalationInstant.getFullYear();
  const escalationInstantMonth = escalationInstant.getMonth();
  const escalationInstantDay = escalationInstant.getDate();

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();

  if (nowYear > escalationInstantYear) { return true; }
  if (nowYear < escalationInstantYear) { return false; }
  if (nowMonth > escalationInstantMonth) { return true; }
  if (nowMonth < escalationInstantMonth) { return false; }
  if (nowDay > escalationInstantDay) { return true; }
  return false;
}

//
// Instant Day
//
export function isNowBeforeInstantDay(instant: Date): boolean {
  if (instant === undefined) { return false; } // defensive code
  
  const instantYear = instant.getFullYear();
  const instantMonth = instant.getMonth();
  const instantDay = instant.getDate();

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();

  if (nowYear < instantYear) { return true; }
  if (nowYear > instantYear) { return false; }
  if (nowMonth < instantMonth) { return true; }
  if (nowMonth > instantMonth) { return false; }
  if (nowDay < instantDay) { return true; }
  return false;
}

export function isNowDuringInstantDay(instant: Date): boolean {
  if ((instant === undefined) || (instant === null)) { return false; } // defensive code

  const now = new Date();
  return ((now.getFullYear() === instant.getFullYear()) && (now.getMonth() === instant.getMonth()) && (now.getDate() === instant.getDate()));
}

export function isNowAfterInstantDay(instant: Date): boolean {
  if ((instant === undefined) || (instant === null)) { return false; } // defensive code

  const instantYear = instant.getFullYear();
  const instantMonth = instant.getMonth();
  const instantDay = instant.getDate();

  const now = new Date();
  const nowYear = now.getFullYear();
  const nowMonth = now.getMonth();
  const nowDay = now.getDate();

  if (nowYear > instantYear) { return true; }
  if (nowYear < instantYear) { return false; }
  if (nowMonth > instantMonth) { return true; }
  if (nowMonth < instantMonth) { return false; }
  if (nowDay > instantDay) { return true; }
  return false;
}

export function isNowDuringOrAfterInstantDay(instant: Date): boolean {
  if ((instant === undefined) || (instant === null)) { return false; } // defensive code

  return isNowDuringInstantDay(instant) || isNowAfterInstantDay(instant);
}

export function isNowSoonInstant(instant: Date, soonDelayInMs: number): boolean {
  if ((instant === undefined) || (instant === null)) { return false; } // defensive code

  const instantInMs = instant.getTime();
  const soonInMs = instantInMs - soonDelayInMs;
  const now = new Date();
  const nowInMs = now.getTime();
  return ((nowInMs > soonInMs) && (nowInMs < instantInMs));
}

export function isNowAfterInstant(instant: Date): boolean {
  if ((instant === undefined) || (instant === null)) { return false; } // defensive code

  const instantInMs = instant.getTime();
  const now = new Date();
  const nowInMs = now.getTime();
  return nowInMs > instantInMs;
}

//
// Period
//
export function getPeriodFromHour(hour: number): Domain.EnumPeriod {
  let period: Domain.EnumPeriod = Domain.EnumPeriod.evening;
  if (hour < 18) { period = Domain.EnumPeriod.afternoon; }
  if (hour < 12) { period = Domain.EnumPeriod.morning; }
  if (hour < 6) { period = Domain.EnumPeriod.overnight; }
  return period;
}

export function getPeriodFromTimestamp(timestamp: number): Domain.EnumPeriod {
  const date = new Date(timestamp);
  const hour = date.getHours();
  return getPeriodFromHour(hour);
}

export function getPeriodFromNow(): Domain.EnumPeriod {
  const now = new Date();  
  return getPeriodFromTimestamp(now.getTime());
}

export function getPeriodRandom(): Domain.EnumPeriod {
  const hour: number = Math.random() * 24;
  return getPeriodFromHour(hour);
}


//
// For TestHelpers
//
export function getIntervalToNextPeriod(): number {
  // find nowTimestamp
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();
  const nowTimestamp = now.getTime();

  // find futureTimestamp
  let futureTimestamp = nowTimestamp;
  const currentPeriod = getPeriodFromNow();
  if (currentPeriod === Domain.EnumPeriod.overnight) {
    futureTimestamp = new Date(year, month, day, 6, 0, 5, 0).getTime();
  } else if (currentPeriod === Domain.EnumPeriod.morning) {
    futureTimestamp = new Date(year, month, day, 12, 0, 5, 0).getTime();
  } else if (currentPeriod === Domain.EnumPeriod.afternoon) {
    futureTimestamp = new Date(year, month, day, 18, 0, 5, 0).getTime();
  } else if (currentPeriod === Domain.EnumPeriod.evening) {
    futureTimestamp = new Date(year, month, (day + 1), 0, 0, 5, 0).getTime();
  }
  // Calculate interval which is the diff between timestamps
  let intervalInMs = Math.abs(futureTimestamp - nowTimestamp);
  if (intervalInMs > 21605000) { intervalInMs = 21605000; } // defensive code - set a max limit to 6 hours and 5 seconds
  return intervalInMs;
}


export function getInstantFromTimestamp(timestamp: number): Date {
  return new Date(timestamp);
}

export function getInstantFromDate(date: string): Date {
  const dateYear = getYearFromDate(date);
  const dateMonth = getMonthIndexFromDate(date);
  const dateDay = getDayFromDate(date);
  return new Date(dateYear, dateMonth, dateDay, 0, 0, 0, 0);
}

export function getInstantByAddingDaysAfterToday(days: number): Date {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  const now = new Date();
  const nowInMs = now.getTime();
  const instant = getInstantFromTimestamp(nowInMs + (oneDayInMs * days));
  const year = instant.getFullYear();
  const month = instant.getMonth();
  const day = instant.getDate();

  const hour: number = Math.floor(24 * Math.random());

  return new Date(year, month, day, hour, 0, 0, 0);
}

export function getBeginOfPeriodInstantByAddingDaysAfterToday(days: number): Date {
  const oneDayInMs: number = 60 * 60 * 24 * 1000;
  const now = new Date();
  const nowInMs = now.getTime();
  const instant = getInstantFromTimestamp(nowInMs + (oneDayInMs * days));
  const year = instant.getFullYear();
  const month = instant.getMonth();
  const day = instant.getDate();

  let hour: number = 23;
  const currentPeriod = getPeriodRandom();
  if (currentPeriod === Domain.EnumPeriod.overnight) {
    hour = 0;
  } else if (currentPeriod === Domain.EnumPeriod.morning) {
    hour = 6;
  } else if (currentPeriod === Domain.EnumPeriod.afternoon) {
    hour = 12;
  } else if (currentPeriod === Domain.EnumPeriod.evening) {
    hour = 18;
  }
  return new Date(year, month, day, hour, 0, 0, 0);
}
